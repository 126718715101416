.custom-input[type=range] {
    margin: 10px;
    position: relative;
    top: -1px;
    border-radius: 10px;
    z-index: 1;
  }

  input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 25%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 25%;
    cursor: pointer;
    height: 7px;
    background: #205928;
    border-radius: 10px;
    border: 1px solid #18D501;
  }
  input[type=range]::-webkit-slider-thumb {
    
    border: 2px solid #83E584;
    height: 15px;
    width: 23px;
    border-radius: 23px;
    background: #439643;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;

  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #205928;
  }
  input[type=range]::-moz-range-track {
    width: 25%;
    height: 7px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #002200;
    background: #205928;
    border-radius: 1px;
    border: 1px solid #18D501;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 3px 3px 3px #00AA00;
    border: 2px solid #83E584;
    height: 6px;
    width: 23px;
    border-radius: 23px;
    background: #439643;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 25%;
    height: 12px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #205928;
    border: 1px solid #18D501;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #002200;
  }
  input[type=range]::-ms-fill-upper {
    background: #205928;
    border: 1px solid #18D501;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #002200;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 3px 3px 3px #00AA00;
    border: 2px solid #83E584;
    height: 23px;
    width: 23px;
    border-radius: 23px;
    background: #439643;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #205928;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #205928;
  }


  body {
    background: linear-gradient(61deg, rgba(2,0,36,1) 0%, rgba(3, 27, 2, 0.981) 90%);    
    font-weight: 10px;
    font-family: 'Open Sans', sans-serif;
    color:white;
  }

  @media only screen and (max-width: 767px) {
    body {
        color:white; 
        background: linear-gradient(61deg, rgba(2,0,36,1) 0%, rgba(3, 27, 2, 0.981) 90%);
    }
  }

  .custom-row {
    background: linear-gradient(61deg, rgba(2,0,36,1) 0%, rgba(22, 4, 45, 0.981) 90%);    
  }

  .custom-card{
    background: linear-gradient(61deg, rgb(2, 2, 2) 0%, rgba(19, 3, 45, 0.981) 90%);    

  }

  .custom-editor {
    box-shadow: 0 0 2px #fff;

  }

  .custom-ai-output {
    opacity: 1;
    font-weight: 400;
    height:800px;
    border-color: white;
    background-color: rgb(232, 250, 252);
    border-radius: 5px;
    border-width: 1px;
    padding: 20px 10px;
    border-style: solid;
    color: black;
    overflow-y: hidden;
  }
  
  .custom-ai-output::-webkit-scrollbar {
    display: none;
  }

  .custom-ai-output2 {
    opacity: 1;
    font-weight: 400;
    border-color: white;
    background-color: rgb(232, 250, 252);
    border-radius: 5px;
    border-width: 1px;
    padding: 20px 10px;
    border-style: solid;
    color: black;
    overflow-y: hidden;
  }

  .custom-console-output {
    margin: 15px;
  font-weight: 400;
  font: 1.3rem Inconsolata, monospace;
  text-shadow: 0 0 2px #7b7b7b;
  box-shadow: 0 0 5px #C8C8C8;
  padding: 10px 5px;
  border-radius: 5px;
  background-color: black;
  background-image: radial-gradient(
    rgba(2, 108, 2, 0.75), black 120%
  );
  height: 100vh;
  color: #9ae61f;
  overflow: auto;
  min-height:150px;
  }
  .custom-console-output::-webkit-scrollbar {
    width: 12px;
  }
  
  .custom-console-output::-webkit-scrollbar-track {
    background: rgb(16, 16, 16);
  }
  
  .custom-console-output::-webkit-scrollbar-thumb {
    background: rgb(101, 45, 138);
  }
  
  .custom-console-output::-webkit-scrollbar-thumb:hover {
    background: #777;
  }

  .text-input {
    font-size: 16px;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 5px #C8C8C8;
    min-height:50px;
    box-sizing: border-box;
    background-color: #f0fafb;
    color: rgb(75, 78, 79);
    text-align: left;
    display: block;
    margin: 3px 0;
  }
  
.text-input:focus {
    outline: none;
    border: 1px solid #3498db;
  }

  .navlink2 {
    position: relative;
    display: inline-flex;
    line-height: 40px;
    margin: 10px 3px;
    padding: 10px 15px;
    color: #a4f79f;
  }

  .link-custom:hover {
    color: white !important;
  }
  
  .link-custom2:hover {
    color: rgb(201, 2, 2) !important;
  }

    .custom-button {
    background-color: #0d9842;
    margin-top:3px;
    box-shadow: 1px 1px .1px #20718f;
    color: #fff;
    height:50px;
    min-width:80px;
    border: none;
    border-radius: 10px;
    margin-left: 2px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.5s ease;

  }

  .custom-button-console {

    box-shadow: 1px 1px .1px #20718f;
    border-color: #107742;
    border-radius: 4px;
    color:rgb(27, 89, 224);
    margin-left: 2px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.5s ease;

  }
  
  .custom-button:hover {
    background-color: #2682c7;
  }

  .custom-button-console:hover {
    background-color: #b8dcf8;
  }


  .text-titles {
    font-size:'18px';
    text-shadow: '0 0 5px #78c93e';
  }

  .message-ai {
    background: #0c506d;
    color: #FFFFFF;
    font-family: Arial;
    box-shadow: 5px 5px 2px 0px #A3A3A3;
    text-align: center;
    border-radius: 24px;
    padding: 13px;
    display: inline-block;
    width: auto;

  }
  
  .message-human {
    background: #440c65;
    color: #FFFFFF;
    font-family: Arial;
    box-shadow: 5px 5px 2px 0px #A3A3A3;
    text-align: center;
    border-radius: 24px;
    padding: 5px;
    float: right;
    width: auto;
    padding: 13px;
  }

  .message-typing {
    background: #0c506d;
    color: #FFFFFF;
    font-style: italic;
    font-family: Arial;
    box-shadow: 5px 5px 2px 0px #A3A3A3;
    text-align: center;
    border-radius: 24px;
    padding: 13px;
    display: inline-block;
    min-width: 140px;
    height:50px;
  }

  .message-typing2 {
    background: #0c506d;
    color: #FFFFFF;
    font-style: italic;
    font-family: Arial;
    box-shadow: 5px 5px 2px 0px #A3A3A3;
    text-align: center;
    border-radius: 24px;
    padding: 13px;
    display: inline-block;
    min-width: 140px;
    height:58px;
  }

  .message-initial {
    background: #0c506d;
    color: #FFFFFF;
    font-family: Arial;
    box-shadow: 5px 5px 2px 0px #A3A3A3;
    border-radius: 24px;
    padding: 13px;
    display: inline-block;
    min-width: 140px;
    height:auto;
  }
  
  .message__createdAt {
    font-style: italic;
    font-family: Arial;
    color: #b0b0b0;
    font-size: 12px;
    clear: both;
  }
 
  .human-time {
    margin-bottom: 14px;
  }

  .message__pic {
    margin: 3px;
    padding: 3px;
  }

  .message__pic-placeholder {
    background-color: purple;
    border-radius: 50%;
  }

  .custom-file-upload {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    font-size: 14px;
    padding: 10px 12px;
    background-color: #22b03e;
    box-shadow: 5px 4px 2px rgba(40, 139, 215, 0.25);
  }

  .custom-image {
    display: inline-flex;
    align-items: center;
    border-radius: 12px;
    font-size: 14px;
    padding: 2px;
    background-color: #7722b0;
    box-shadow: 3px 2px 0px rgba(24, 163, 78, 0.25);
    margin-bottom: 4px;
  }
  

  .custom-file-upload:hover {
    transform: translateY(-1px);
    background-color: #21b23e;
    box-shadow: 3px 4px 0px rgba(65, 5, 105, 0.25)
  }

  .min-max:hover {
    transform: translateY(-1px);
    height:44px;
    box-shadow: 1px 2px 0px rgba(24, 163, 78, 0.25);
  }

  .progress-container {
    max-width: 200px;
    border-radius: 5px;
    box-shadow: 0 0 4px #22b03e;
    margin: 0 auto; /* Center the container horizontally */
  }



  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  /* To make the scrollbar always visible */
::-webkit-scrollbar {
  width: 6px;
}

/* To make the scrollbar purple */
::-webkit-scrollbar-thumb {
  background-color: rgb(210, 209, 210);
  border-radius: 6px;
}


.hovertext {
  position: relative;
  border-bottom: 1px dotted black;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  max-width: 68px;
  background-color: rgb(49, 29, 80);
  font-size: 14px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  margin:1px;
  transition: opacity .7s ease-in-out;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.hovertext:hover:before {
  opacity: .8;
  visibility: visible;
}


.message__markdown {
  max-width: 100%;
}


.message__markdown.streaming .message-ai {
  background-color: #ffdfc9;
  animation: pulse 0.5s infinite alternate;
}

@keyframes pulse {
  from {
    background-color: #fff;
  }

  to {
    background-color: #ffdfc9;
  }
}

.my-hcaptcha {
  width: 10px;
  border: 1px solid black;
  border-radius: 5.1px;
}