.custom-input[type=range] {
    margin: 10px;
    position: relative;
    top: -1px;
    border-radius: 10px;
    z-index: 1;
  
  }

  input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 25%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 25%;
    cursor: pointer;
    height: 7px;
    background: #205928;
    border-radius: 10px;
    border: 1px solid #18D501;
  }
  input[type=range]::-webkit-slider-thumb {
    
    border: 2px solid #83E584;
    height: 15px;
    width: 23px;
    border-radius: 23px;
    background: #439643;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;

  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #205928;
  }
  input[type=range]::-moz-range-track {
    width: 25%;
    height: 7px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #002200;
    background: #205928;
    border-radius: 1px;
    border: 1px solid #18D501;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 3px 3px 3px #00AA00;
    border: 2px solid #83E584;
    height: 6px;
    width: 23px;
    border-radius: 23px;
    background: #439643;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 25%;
    height: 12px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #205928;
    border: 1px solid #18D501;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #002200;
  }
  input[type=range]::-ms-fill-upper {
    background: #205928;
    border: 1px solid #18D501;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #002200;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 3px 3px 3px #00AA00;
    border: 2px solid #83E584;
    height: 23px;
    width: 23px;
    border-radius: 23px;
    background: #439643;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #205928;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #205928;
  }


  body {
    background: linear-gradient(61deg, rgba(2,0,36,1) 0%, rgba(3, 27, 2, 0.981) 90%);    
    font-weight: 10px;
    font-family: 'Open Sans', sans-serif;
    color:white;
  }

  @media only screen and (max-width: 767px) {
    body {
        color:white; 
        background: linear-gradient(61deg, rgba(2,0,36,1) 0%, rgba(3, 27, 2, 0.981) 90%);
    }
  }

  .custom-row {
    background: linear-gradient(61deg, rgba(2,0,36,1) 0%, rgba(22, 4, 45, 0.981) 90%);    
  }

  .custom-card{
    background: linear-gradient(61deg, rgb(2, 2, 2) 0%, rgba(19, 3, 45, 0.981) 90%);    

  }

  .custom-editor {
    box-shadow: 0 0 2px #fff;

  }

  .custom-ai-output {
    opacity: 1;
    font-weight: 400;
    max-height: 300px;
    min-height: 300px;
    border-color: white;
    background-color: rgb(232, 250, 252);
    border-radius: 5px;
    border-width: 1px;
    padding: 20px 10px;
    border-style: solid;
    color: black;
    overflow: scroll;
  }

  .custom-console-output {
    margin: 15px;
  font-weight: 400;
  font: 1.3rem Inconsolata, monospace;
  text-shadow: 0 0 2px #C8C8C8;

  padding: 10px 5px;
  border-radius: 5px;
  background-color: black;
  background-image: radial-gradient(
    rgba(0, 150, 0, 0.75), black 120%
  );
  height: 100vh;
  color: #87CB16;
  overflow: auto;
  height: 100px;
  }

  .text-input {
    font-size: 16px;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 5px #C8C8C8;
    min-height:50px;
    box-sizing: border-box;
    background-color: #f0fafb;
    color: rgb(75, 78, 79);
    text-align: left;
    display: block;
    margin: 3px 0;
  }
  
.text-input:focus {
    outline: none;
    border: 1px solid #3498db;
  }

  .navlink2 {
    position: relative;
    display: inline-flex;
    line-height: 40px;
    margin: 10px 3px;
    padding: 10px 15px;
    color: #a4f79f;
  }

  .link-custom:hover {
    color: white !important;
  }
  
  
  .custom-button {
    background-color: #0d9842;
    margin-top:3px;
    box-shadow: 1px 1px .1px #20718f;
    color: #fff;
    height:50px;
    min-width:80px;
    border: none;
    border-radius: 10px;
    margin-left: 2px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.5s ease;

  }
  
  .custom-button:hover {
    background-color: #2682c7;
  }

  .text-titles {
    font-size:'18px';
    text-shadow: '0 0 5px #78c93e';
  }

